import { ReactNode } from 'react';

type ModalProps = {
  children: ReactNode;
  open: boolean;
  close: () => void;
};

const Modal = ({ children, open, close }: ModalProps) => {
  return (
    <div className={`${open ? 'flex' : 'hidden'} fixed top-0 z-[120] h-screen w-screen`}>
      <div onClick={close} className='absolute z-0 h-full w-full bg-black bg-opacity-75' />
      <div className='z-50 m-auto'>{children}</div>
    </div>
  );
};

export default Modal;
