import { Suspense, lazy, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';

import Background from '../../assets/images/Background24.png';
import { Header } from '../../components';
// import Chest from '../../pages/Chest';
// import NotFound from '../../pages/Error/NotFound';
// import ItemsetChest from '../../pages/ItemsetChest';
import NotFound from '../../pages/Error/NotFound';
import Loading from '../../pages/Loading';
import MinigameQuestion from '../../pages/MinigameQuestion';
import MinigameQuestionList from '../../pages/MinigameQuestion/MinigameQuestionList';
import Pictures from '../../pages/Pictures';
// import Tasks from '../../pages/Tasks';
// import useBoundStore from '../../store';

// const HomePage = lazy(() => import('../../pages/Home'));
const Leaderboard = lazy(() => import('../../pages/Leaderboard'));
const MissionsPage = lazy(() => import('../../pages/Missions'));
// const ItemDetail = lazy(() => import('../../pages/ItemDetail'));
// const Shop = lazy(() => import('../../pages/Shop'));
const NotificationsPage = lazy(() => import('../../pages/Notifications'));
// const ScorePage = lazy(() => import('../../pages/Score'));
// const AdminCheckIn = lazy(() => import('../../pages/AdminCheckIn'));
const StationMap = lazy(() => import('../../pages/Map'));
// const Gacha = lazy(() => import('../../pages/Gacha'));
// const Login = lazy(() => import('../../pages/Login'));

const UserRouter = () => {
  // const isAuthenticated = useBoundStore.use.isAuthenticated();
  const isInit = useRef(false);
  const initFunction = function () {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    if (!canvas) {
      return;
    }

    (function setSize() {
      const w = window.innerWidth;
      const h = window.innerHeight;
      canvas.width = w;
      canvas.height = h + 20;
    })();

    const c = canvas.getContext('2d');
    if (!c) {
      throw new Error('Could not get 2D context from canvas');
    }

    const numStars = 800;
    const radius = `0.${Math.floor(Math.random() * 9)}${1}`;
    const focalLength = canvas.width * 2;
    const warp = 0;
    let centerX: number;
    let centerY: number;

    let stars: { x: number; y: number; z: number; o: string }[] = [];
    let star: { x: number; y: number; z: number; o: string };
    let i: number;

    const animate = true;

    function initializeStars() {
      centerX = canvas.width / 2;
      centerY = canvas.height / 2;

      stars = [];
      for (i = 0; i < numStars; i++) {
        star = {
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          z: Math.random() * canvas.width,
          o: `0.${Math.floor(Math.random() * 99)}${1}`,
        };
        stars.push(star);
      }
    }

    function moveStars() {
      for (i = 0; i < numStars; i++) {
        star = stars[i];
        star.z--;

        if (star.z <= 0) {
          star.z = canvas.width;
        }
      }
    }

    function drawStars() {
      let pixelX;
      let pixelY;
      let pixelRadius;

      // Resize to the screen
      if (canvas.height != window.innerHeight || canvas.width != window.innerWidth) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        initializeStars();
      }
      if (warp == 0) {
        if (c) {
          c.fillStyle = 'rgba(0,10,20,1)';
          c.fillRect(0, 0, canvas.width, canvas.height);
        } else {
          console.error('Could not get 2D context from canvas');
        }
      }
      if (c) {
        c.fillStyle = `rgba(209, 255, 255, ${radius})`;
        for (i = 0; i < numStars; i++) {
          star = stars[i];

          pixelX = (star.x - centerX) * (focalLength / star.z);
          pixelX += centerX;
          pixelY = (star.y - centerY) * (focalLength / star.z);
          pixelY += centerY;
          pixelRadius = 1 * (focalLength / star.z);

          c.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
          c.fillStyle = `rgba(209, 255, 255, ${star.o})`;
          c.fill();
        }
      }
    }

    initializeStars();

    function executeFrame() {
      if (animate) {
        window.requestAnimationFrame(executeFrame);
      }
      moveStars();
      drawStars();
    }

    executeFrame();
  };

  const initFunc = () => {
    if (isInit.current) {
      return <></>;
    }
    isInit.current = true;
    setTimeout(initFunction, 100);
    return <></>;
  };

  return (
    <>
      <Header />
      <div
        className='canvas-box flex flex-1'
        style={{ position: 'fixed', top: 0, left: 0, height: '100%' }}
      >
        <div
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            zIndex: 3,
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            position: 'fixed',
            opacity: 0.8,
          }}
        ></div>
        <canvas
          className='flex flex-1'
          style={{ position: 'fixed', maxWidth: window.innerWidth, maxHeight: window.innerHeight }}
          id='canvas'
        >
          canvas
        </canvas>
      </div>
      <Routes>
        {/* <Route
          path='*'
          element={
            <Suspense fallback={<Loading />}>
              <Leaderboard />
            </Suspense>
          }
        /> */}
        <Route
          path=''
          element={
            <Suspense fallback={<Loading />}>
              <StationMap />
            </Suspense>
          }
        />
        {/* <Route path='shop'>
          <Route
            path=''
            element={
              <Suspense fallback={<Loading />}>
                <Shop />
              </Suspense>
            }
          />
          <Route
            path='gacha'
            element={
              <Suspense fallback={<Loading />}>
                <Gacha />
              </Suspense>
            }
          />
          <Route
            path=':type/:id'
            element={
              <Suspense fallback={<Loading />}>
                <ItemDetail />
              </Suspense>
            }
          />
        </Route> */}
        <Route
          path='notifications'
          element={
            <Suspense fallback={<Loading />}>
              <NotificationsPage />
            </Suspense>
          }
        />
        <Route
          path='leaderboard'
          element={
            <Suspense fallback={<Loading />}>
              <Leaderboard />
            </Suspense>
          }
        />
        <Route path='pictures'>
          <Route
            path=''
            element={
              <Suspense fallback={<Loading />}>
                <Pictures />
              </Suspense>
            }
          />
          <Route
            path=':pictureId'
            element={
              <Suspense fallback={<Loading />}>
                <MinigameQuestionList />
              </Suspense>
            }
          />
          <Route
            path=':pictureId/questions/:questionId'
            element={
              <Suspense fallback={<Loading />}>
                <MinigameQuestion />
              </Suspense>
            }
          />
        </Route>
        <Route path='*' element={<NotFound />} />
        {/* <Route
          path='score'
          element={
            <Suspense fallback={<Loading />}>
              <ScorePage />
            </Suspense>
          }
        />
        {/* <Route path='code' element={<Suspense fallback={<Loading />}></Suspense>} />
        <Route
          path='checkin'
          element={
            <Suspense fallback={<Loading />}>
              <AdminCheckIn />
            </Suspense>
          }
        /> */}
        {/* <Route path='*' element={<NotFound />} /> */}
        {/* <Route
          path='*'
          element={
            <Suspense fallback={<Loading />}>
              <Leaderboard />
            </Suspense>
          }
        /> */}
        {/* <Route
          path='chest'
          element={
            <Suspense fallback={<Loading />}>
              <Chest />
            </Suspense>
          }
        />
        <Route
          path='tasks'
          element={
            <Suspense fallback={<Loading />}>
              <Tasks />
            </Suspense>
          }
        />
        <Route
          path='chest/itemset'
          element={
            <Suspense fallback={<Loading />}>
              <ItemsetChest />
            </Suspense>
          }
        /> */}
      </Routes>
      <>{initFunc()}</>

      {/* <BottomNav /> */}
    </>
  );
};

export default UserRouter;
