import { API_URL } from '../configs';
import { axios } from '../utils/custom_axios';

import { GetPictureListResponse, MinigameQuestionResponse } from '../types';

interface SubmitAnswerResponse {
  pictureId: string;
  questionId: string;
  isCorrect: boolean;
}

const getAllQuestions = (pictureId: string) =>
  axios.get<MinigameQuestionResponse>(`${API_URL}minigame/pictures/${pictureId}/questions`);

const submitAnswer = (pictureId: string, questionId: string, answer: string) =>
  axios.post<SubmitAnswerResponse>(
    `${API_URL}minigame/pictures/${pictureId}/questions/${questionId}/submit`,
    {
      answer,
    }
  );

const getPictureList = () => axios.get<GetPictureListResponse>(`${API_URL}minigame/pictures`);

interface StoryProp {
  story: string;
  backgroundURL: string;
}

const getStory = (picture_id: string) =>
  axios.get<StoryProp>(`${API_URL}minigame/pictures/${picture_id}/story`);

interface FullStoryProp {
  story: string;
}

const getFullStory = () => {
  return axios.get<FullStoryProp>(`${API_URL}minigame/story`);
};

const MinigameService = {
  getPictureList,
  getAllQuestions,
  submitAnswer,
  getStory,
  getFullStory,
};

export default MinigameService;
