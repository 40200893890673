import { FC, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as MagnifyingGlass } from '../../assets/svgs/MagnifyingGlass.svg';
import useBoundStore from '../../store';

import { UserRole } from '../../types';

interface BottomNavProps {}

const BottomNav: FC<BottomNavProps> = () => {
  const user = useBoundStore.use.user();
  const { pathname } = useLocation();

  const isStation = useMemo(
    () =>
      user.role === UserRole.SINGLE_STATION ||
      user.role === UserRole.DUO_STATION ||
      user.role === UserRole.BATTLE_STATION,
    [user]
  );

  const isAdmin = useMemo(() => user.role === UserRole.ADMIN, [user]);

  if (isStation || (isAdmin && pathname.includes('admin'))) {
    return (
      <>
        <nav
          className='btm-nav btm-nav-sm fixed bottom-0 left-0 z-[100] grid h-14 w-screen grid-flow-col 
      grid-cols-2 items-center bg-[rgba(0,0,0,0.1)] px-5'
        >
          <NavLink to='/notifications' className='max-w-1/3 bg-transparent'>
            <img
              src={require('../../assets/images/letter.png')}
              alt='notifications icon'
              className='my-3 aspect-auto h-8'
            />
          </NavLink>
          <NavLink to='/station' className='max-w-1/3 bg-transparent'>
            {/* <img
              src={require('../../assets/svgs/MagnifyingGlass.svg')}
              className='my-3 aspect-auto h-8'
            /> */}
            <MagnifyingGlass className='my-3 aspect-auto h-8' />
          </NavLink>
        </nav>
      </>
    );
  }

  return (
    <>
      <nav
        className='btm-nav btm-nav-sm fixed bottom-0 left-0 z-[100] grid h-14 w-screen 
      grid-flow-col grid-cols-3 items-center bg-[rgba(0,0,0,0)] px-5'
      >
        <NavLink to='/shop' className='max-w-1/3 bg-transparent'>
          <img
            src={require('../../assets/images/shop.png')}
            alt='shop icon'
            className='my-3 aspect-auto h-8'
          />
        </NavLink>
        <NavLink to='/chest' className='max-w-1/3 bg-transparent'>
          <img
            src={require('../../assets/images/chest.png')}
            alt='chest icon'
            className='my-3 aspect-auto h-8'
          />
        </NavLink>
        <NavLink to='/' className='max-w-1/3 bg-transparent'>
          <img
            src={require('../../assets/images/map.png')}
            alt='stations icon'
            className='my-3 aspect-auto h-8'
          />
        </NavLink>
      </nav>
    </>
  );
};

export default BottomNav;
