// eslint-disable-next-line import/no-extraneous-dependencies
import { StateCreator } from 'zustand';

export interface TStoryModalState {
  isShowingFullStoryModal: boolean;
}

export interface TStoryModalActions {
  showFullStoryModal: () => void;
  hideFullStoryModal: () => void;
}

export interface TStoryModalSlice extends TStoryModalState, TStoryModalActions {}

export const initialState = {
  isShowingFullStoryModal: false,
};

export const StoryModalSlice: StateCreator<
  TStoryModalSlice,
  [],
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  TStoryModalSlice
> = (set) => ({
  ...initialState,
  showFullStoryModal: () => {
    set({ isShowingFullStoryModal: true });
  },
  hideFullStoryModal: () => {
    set({ isShowingFullStoryModal: false });
  },
});
