import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import plank from '../../assets/images/plank.png';
import sign from '../../assets/images/sign.png';
import { ReactComponent as ExclamationMark } from '../../assets/svgs/ExclamationMark.svg';
import { ReactComponent as Minigame } from '../../assets/svgs/Minigame.svg';
import { ReactComponent as Notification } from '../../assets/svgs/Notification.svg';
import { ReactComponent as Pole } from '../../assets/svgs/Pole.svg';
import Station from '../../assets/svgs/Station.svg';
import MinigameService from '../../services/minigame.service';
import { socket } from '../../socket';

import { SocketEvent } from '../../types';
// import { ReactComponent as Thunder } from '../../assets/svgs/Thunder.svg';

type SignType = {
  name: string;
  redirectLink: string;
  children: ReactNode;
  withNotification?: boolean;
  onClick: () => void;
};

const Sign = ({ name, redirectLink, children, withNotification = false, onClick }: SignType) => {
  return (
    <Link to={redirectLink} onClick={onClick}>
      <div className='relative flex h-[4rem] w-[5rem] flex-col items-center justify-center gap-2'>
        <div className='relative flex h-[4rem] w-[5rem] items-center justify-center'>
          <img src={sign} className='absolute h-full w-full object-fill' />
          <div className='relative flex flex-col items-center justify-center'>{children}</div>
        </div>
        <div className='relative hidden h-[2rem] w-[5rem] items-center justify-center'>
          <img src={plank} className='object-stretch absolute h-full w-full' />
          <div className='relative items-center text-center text-[12px] font-bold text-[#412708]'>
            {name}
          </div>
        </div>
        {withNotification && (
          <div className='absolute right-[-4px] top-[-4px] flex h-5 w-5 items-center justify-center rounded-full bg-white'>
            <ExclamationMark className='h-5 w-5 fill-[#db4437]' />
          </div>
        )}
      </div>
    </Link>
  );
};

const RedirectBar = () => {
  const [showNotification, setShowNotification] = useState(false);

  const performPageTask = (page: string) => {
    if (page === 'minigame') socket.emit(SocketEvent.MINIGAME_BUTTON_PRESSED);
    else socket.emit(SocketEvent.PAGE_REDIRECT, { buttonClicked: page });
  };

  const handleNotification = () => {
    MinigameService.getPictureList()
      .then((res) => {
        const pictureWithNoti = Object.values(res.data.pictures).filter(
          (picture) => picture.showNotification
        );
        setShowNotification(pictureWithNoti.length > 0);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    handleNotification();
  }, []);

  useEffect(() => {
    socket.on(SocketEvent.QUESTION_CHANGE, handleNotification);

    return () => {
      socket.off(SocketEvent.QUESTION_CHANGE, handleNotification);
    };
  }, []);

  useEffect(() => {}, [showNotification]);

  return (
    <div className='relative flex h-[4rem] w-full flex-col items-center justify-center'>
      <Pole className='absolute z-0 h-[] w-full' />
      <div className='relative z-50 flex flex-row items-center justify-center gap-2 px-3'>
        <Sign
          name='Minigame'
          redirectLink='/pictures'
          withNotification={showNotification}
          onClick={() => performPageTask('minigame')}
        >
          <Minigame className='relative h-[3rem] object-contain' />
        </Sign>
        <Sign name='Stations' redirectLink='/' onClick={() => performPageTask('station')}>
          {/* <Station className='relative h-[3rem] object-contain' />
           */}
          <img src={Station} className='relative w-full object-contain' />
        </Sign>
        <Sign
          name='Notifications'
          redirectLink='/notifications'
          onClick={() => performPageTask('notification')}
        >
          <Notification className='relative h-[3rem] w-[160%] object-contain' />
        </Sign>
      </div>
    </div>
  );
};

export default RedirectBar;
