import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import paper from '../../assets/images/paper24.png';
import { Page } from '../../layouts';
import MinigameService from '../../services/minigame.service';
import { socket } from '../../socket';
import useBoundStore from '../../store';
import Loading from '../Loading';

import { QuestionResponse, SocketEvent } from '../../types';

const MinigameQuestion = () => {
  const { questionId, pictureId } = useParams();
  const userProfile = useBoundStore.use.user();
  const [currentQuestion, setCurrentQuestion] = useState<QuestionResponse | undefined>(undefined);
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (questionId && pictureId) {
      setLoading(true);
      MinigameService.getAllQuestions(pictureId)
        .then((res) => {
          const questionList = Object.values(res.data);
          if (questionList.filter((question) => question.isUnlocked).length === 0) {
            toast.error('You have not unlocked this picture');
            navigate('/pictures');
            return;
          }
          const question = questionList[Number(questionId) - 1];
          if (!question.isUnlocked) {
            toast.error('You have not unlocked this question');
            navigate(`/pictures/${pictureId}`);
          } else if (question.isAnswered) {
            toast.error('You have already answered this question');
            navigate(`/pictures/${pictureId}`);
          } else setCurrentQuestion(question);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
        })
        .finally(() => setLoading(false));
    }
  }, [questionId, pictureId, navigate]);

  useEffect(() => {
    if (questionId && pictureId && currentQuestion) {
      socket.emit(SocketEvent.VIEW_QUESTION, { pictureId, questionId });
    }
  }, [pictureId, questionId, currentQuestion]);

  if (!questionId || !pictureId || !currentQuestion) return null;

  const handleSubmitAnswer = async () => {
    try {
      await MinigameService.submitAnswer(pictureId, questionId, answer);
      toast.success('Your answer is correct');
      navigate(`/pictures/${pictureId}`);
    } catch (e) {
      toast.info('Your answer is incorrect!');
    }
  };

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={`${userProfile.name}`}>
      <div className='mx-5 mt-10'>
        <div className='relative mb-4 aspect-square p-8'>
          <img src={paper} className='absolute left-0 top-0 z-0 h-full w-full' />
          <img
            src={currentQuestion.picture || ''}
            alt='question'
            className='relative h-full w-full'
          />
        </div>
        <input
          type='text'
          value={answer}
          onInput={(e) => setAnswer(e.currentTarget.value)}
          className='mb-5 h-[40px] w-full rounded-lg bg-[#fee3ba] p-2 text-black'
          placeholder='Your answer'
        />
        <button onClick={handleSubmitAnswer} className='h-[40px] w-full rounded-lg bg-[#FBB03B]'>
          Submit
        </button>
      </div>
    </Page>
  );
};

export default MinigameQuestion;
