// import { Card } from './game';

// import { CompletedTask } from '../services/task.service';

// import { Item } from './item';
// import { ItemSets } from './set';

export enum UserRole {
  PLAYER = 'PLAYER',
  ADMIN = 'ADMIN',
  SINGLE_STATION = 'SINGLE_STATION',
  DUO_STATION = 'DUO_STATION',
  BATTLE_STATION = 'BATTLE_STATION',
}

export interface User {
  id: string;
  email: string;
  role: UserRole;
  name: string;
  skName?: string;
  points?: number;
  password?: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
}
