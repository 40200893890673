import { UserRole } from './user';

export enum Status {
  PREPARATION = 'PREPARATION',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type Station = {
  id: string;
  name: string;
  role: UserRole;
  email: string;
  avatar: string;
};
