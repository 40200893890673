import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { BottomNav, Header } from '../../components';
import NotFound from '../../pages/Error/NotFound';
import Loading from '../../pages/Loading';

// const HomePage = lazy(() => import('../../pages/Home'));
const StationManagement = lazy(() => import('../../pages/StationMaster/Management'));
const Notifications = lazy(() => import('../../pages/Notifications'));

const AdministrativeRouter = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<h1>Admin Home</h1>} />
        <Route
          path='station'
          element={
            <Suspense fallback={<Loading />}>
              <StationManagement />
            </Suspense>
          }
        />
        <Route
          path='notifications'
          element={
            <Suspense fallback={<Loading />}>
              <Notifications />
            </Suspense>
          }
        />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <BottomNav />
    </>
  );
};

export default AdministrativeRouter;
