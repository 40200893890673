import { API_URL } from '../configs';
import { axios } from '../utils/custom_axios';

import { Notification, User } from '../types';

export enum UserRole {
  STUDENT = 'STUDENT',
  ADMIN = 'ADMIN',
}

const getUserProfile = () => axios.get<User>(`${API_URL}users/me`);

type GetAllNotificationsReturnType = {
  totalItems: number;
  totalPages: number;
  page: number;
  pageSize: number;
  notifications: Notification[];
};

const getAllNotifications = () =>
  axios.get<GetAllNotificationsReturnType>(`${API_URL}users/me/notifications?sort=created_at|desc`);

const UserService = {
  getUserProfile,
  getAllNotifications,
};

export default UserService;
