import { DetailedHTMLProps, HTMLAttributes, ReactNode, forwardRef, useEffect } from 'react';

import FullStoryModalIdle from '../components/FullStoryModal';
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE, TITLE } from '../configs';
import { socket } from '../socket';
import useBoundStore from '../store';

import { SocketEvent } from '../types';

type PageProps = {
  children: ReactNode;
  title?: string;
  description?: string;
  style?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>['style'];
};

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title, description, style, ...props }, _ref) => {
    const isShowingFinalStoryModal = useBoundStore((state) => state.isShowingFullStoryModal);
    const showFinalStoryModal = useBoundStore((state) => state.showFullStoryModal);
    const hideFinalStoryModal = useBoundStore((state) => state.hideFullStoryModal);

    useEffect(() => {
      document.title = `${title ? `${title} | ${TITLE}` : DEFAULT_TITLE}`;
      document
        ?.querySelector('meta[name="description"]')
        ?.setAttribute('content', description || DEFAULT_DESCRIPTION || '');
    }, [title, description]);
    useEffect(() => {
      const onEndGame = (res: { story: string }) => {
        localStorage.setItem('endGameMessage', res.story);
        showFinalStoryModal();
      };

      socket.on(SocketEvent.GAME_END, onEndGame);

      return () => {
        socket.off(SocketEvent.GAME_END, onEndGame);
      };
    }, [showFinalStoryModal]);

    return (
      <div
        className='h-full w-screen'
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        style={{ ...style, position: 'relative' }}
      >
        {children}
        <FullStoryModalIdle
          isOpen={isShowingFinalStoryModal}
          closeModal={() => {
            hideFinalStoryModal();
          }}
        />
      </div>
    );
  }
);
Page.displayName = 'Page';

export default Page;
