import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import paper from '../../assets/images/paper24.png';
import { ReactComponent as InkTrail1 } from '../../assets/svgs/InkTrail1.svg';
import MinigameService from '../../services/minigame.service';
import Modal from '../Modal';

interface FullStoryModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const FullStoryModalIdle = ({ isOpen, closeModal }: FullStoryModalProps) => {
  const [story, setStory] = useState('');
  const [loading, setLoading] = useState(false);
  const endGameMessage = localStorage.getItem('endGameMessage');

  useEffect(() => {
    if (endGameMessage) {
      setStory(endGameMessage);
      return;
    }
    if (isOpen && !endGameMessage) {
      (async () => {
        setLoading(true);
        try {
          const response = await MinigameService.getFullStory();
          setStory(response.data.story);
        } catch (e) {
          console.error(e);
          toast.error('An error occurred while fetching the story');
          closeModal();
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [isOpen, closeModal, endGameMessage]);

  if (!story) return null;

  return (
    <Modal
      open={isOpen}
      close={() => {
        if (endGameMessage) return;
        closeModal();
      }}
    >
      <div className='relative flex h-[32.5rem] w-[22.5rem] flex-col items-center justify-between rounded-lg text-[#582F00]'>
        <img src={paper} className='object-stretch absolute z-0 h-full w-full' />
        <div className='relative my-4 flex h-full w-full flex-col items-center justify-start overflow-y-scroll rounded-lg p-[2.5rem]'>
          <div className='relative flex flex-col'>
            <div className='flex items-center justify-center text-center text-base font-bold'>
              {endGameMessage ? 'The game has ended' : 'Full story'}
            </div>
            <InkTrail1 className='relative h-[0.875rem] w-[12.5rem] fill-[#582F00]' />
          </div>
          <div className=' mt-[2rem] flex w-full items-start justify-center text-center text-sm font-normal'>
            <p className='text-justify'>{loading ? 'Loading...' : story}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FullStoryModalIdle;
