export enum NotificationType {
  COMPLETED_SINGLE_STATION = 'COMPLETED_SINGLE_STATION',
  COMPLETED_DUO_STATION = 'COMPLETED_DUO_STATION',
  COMPLETED_BATTLE_STATION = 'COMPLETED_BATTLE_STATION',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  STATION_SESSION_ENDED_FOR_STATION = 'STATION_SESSION_ENDED_FOR_STATION',
  MINIGAME_QUESTION_FINISHED = 'MINIGAME_QUESTION_FINISHED',
  MINIGAME_UNLOCK_QUESTIONS = 'MINIGAME_UNLOCK_QUESTIONS',
  // BUY_ITEM = 'BUY_ITEM',
  // FORGE_ITEM_SET = 'FORGE_ITEM_SET',
  // SHOP_RENEW = 'SHOP_RENEW',
  // GACHA = 'GACHA',
  // TASK = 'TASK',
}

export type Notification = {
  id: string;
  type: string;
  description: string;
  createdAt: string;
};
