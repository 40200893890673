import { StateCreator } from 'zustand';

import UserService from '../services/user.service';

import { TAuthSlice } from './auth';

import { User, UserRole } from '../types';

export interface TUserSlice {
  user: User;
  getUserProfile: () => Promise<void>;
}

export const initialState = {
  id: '',
  email: '',
  role: UserRole.PLAYER,
  name: '',
  skName: '',
  points: 0,
  avatar: '',
  createdAt: '',
  updatedAt: '',
  password: '',
};

export const UserSlice: StateCreator<
  TUserSlice & TAuthSlice,
  [],
  [['zustand/persist', unknown], ['zustand/devtools', never]],
  TUserSlice
> = (set) => ({
  user: initialState,
  getUserProfile: async () => {
    try {
      const { data } = await UserService.getUserProfile();
      // if (
      //   (payload && payload.roles.includes(UserRole.SINGLE_STATION)) ||
      //   payload.roles.includes(UserRole.DUO_STATION) ||
      //   payload.roles.includes(UserRole.BATTLE_STATION)
      // ) {
      //   if (!window.location.href.includes('/admin/station')) {
      //     window.location.replace(window.location.href + 'admin/station');
      //   }
      // }

      set((state) => ({
        user: {
          ...state.user,
          id: data?.id,
          email: data?.email,
          role: data?.role || UserRole.PLAYER,
          name: data?.name,
          skName: data?.skName,
          points: data?.points || 0,
          avatar: data?.avatar,
          password: data?.password,
          createdAt: data?.createdAt,
          updatedAt: data?.updatedAt,
        },
        isAuthenticated: true,
      }));
    } catch (error: any) {
      set({ user: { ...initialState }, isAuthenticated: false });
    }
  },
});
