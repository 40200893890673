import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import board from '../../assets/images/board.png';
import plank from '../../assets/images/plank.png';
import { ReactComponent as ExclamationMark } from '../../assets/svgs/ExclamationMark.svg';
import { PictureList } from '../../constants/minigame';
import { Page } from '../../layouts';
import MinigameService from '../../services/minigame.service';
import useBoundStore from '../../store';
import Loading from '../Loading';
import RedirectBar from '../Map/RedirectBar';

import { Picture, PictureResponse } from '../../types';

interface PictureRowProps extends Picture {
  isLocked: boolean;
  withNotification?: boolean;
}

const PictureRow = ({
  pictureId,
  name,
  imageUrl,
  isLocked,
  withNotification = false,
}: PictureRowProps) => {
  return (
    <Link
      to={`/pictures/${pictureId}`}
      className={`relative flex h-[5rem] w-full flex-row items-center justify-between border-[#D2A36D] bg-[#F9BC74] text-[20px] font-normal text-[#412708] shadow-sm
        ${isLocked && 'pointer-events-none opacity-50'}`}
    >
      <img src={imageUrl} className='object-stretch absolute h-[80px] w-full object-cover' />
      <div className='relative flex h-full flex-col items-start justify-between px-5 py-3'>
        <div className='text-white'>Canvas #{pictureId}</div>
        <div className='font-bold text-white'>{name}</div>
      </div>
      {withNotification && (
        <div className='absolute right-2 top-2 flex h-7 w-7 items-center justify-center rounded-full border border-[#db4437]'>
          <ExclamationMark className='h-5 w-5 fill-[#db4437]' />
        </div>
      )}
    </Link>
  );
};

const Pictures = () => {
  const [loading, setLoading] = useState(false);
  const [minigamePictures, setMinigamePictures] = useState<PictureResponse[]>();
  const [isAllowedFullStory, setIsAllowedFullStory] = useState(false);

  const showFullStoryModal = useBoundStore((state) => state.showFullStoryModal);

  useEffect(() => {
    setLoading(true);
    MinigameService.getPictureList()
      .then((res) => {
        setMinigamePictures(Object.values(res.data.pictures));
        setIsAllowedFullStory(res.data.canViewFullStory);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title='Home'>
      <div className='relative flex h-screen w-full flex-col items-center p-3'>
        <RedirectBar />
        {isAllowedFullStory && (
          <div className='mt-5 flex w-full flex-col items-stretch'>
            <div className='relative mb-5 flex h-20 w-full items-stretch'>
              <img src={plank} className='absolute left-0 top-0 z-0 h-full w-full' />
              <button
                onClick={showFullStoryModal}
                className='relative z-10 w-full text-lg font-bold text-black'
              >
                Reveal The Full Story
              </button>
            </div>
            <div className='mb-2 flex items-center self-stretch'>
              <hr className='flex-grow' />
              <span className='mx-2 font-bold text-white'>OR</span>
              <hr className='flex-grow' />
            </div>
            <p className='self-center'>Continue to earn more Jigsaws</p>
          </div>
        )}
        <div className='relative mt-5 w-full font-bold text-[#412708] shadow-xl'>
          <img src={board} className='absolute left-0 top-0 z-0 h-full w-full' />
          <div className='relative z-10 mt-0 flex h-full flex-col gap-5 px-5 py-3 '>
            <div className='relative flex h-full w-full flex-col items-center justify-start gap-2 rounded-lg py-4'>
              {PictureList.map((picture, idx) => (
                <PictureRow
                  key={picture.pictureId}
                  pictureId={picture.pictureId}
                  name={picture.name}
                  imageUrl={minigamePictures?.[idx].thumbnail || picture.imageUrl}
                  isLocked={!minigamePictures?.[idx].isUnlocked}
                  withNotification={minigamePictures?.[idx].showNotification}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Pictures;
