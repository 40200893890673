export enum SocketEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',

  NOTIFICATION = 'NOTIFICATION',
  LEADERBOARD = 'LEADERBOARD',
  ADMIN_STATION_SESSION_CHANGE = 'ADMIN_STATION_SESSION_CHANGE',

  STATION_LIST_CHANGE = 'STATION_LIST_CHANGE',

  MY_STATION_SESSION_CHANGE = 'MY_STATION_SESSION_CHANGE',
  SHOP_RENEW = 'SHOP_RENEW',

  STORY_EVENT = 'STORY_EVENT',
  QUESTION_CHANGE = 'QUESTION_CHANGE',
  VIEW_QUESTION = 'VIEW_QUESTION',
  MINIGAME_BUTTON_PRESSED = 'MINIGAME_BUTTON_PRESSED',
  SCROLLING_EVENT = 'SCROLLING_EVENT',
  PAGE_REDIRECT = 'PAGE_REDIRECT',
  STATION_SELECTED = 'STATION_SELECTED',
  GAME_END = 'GAME_END',
}
