import { useRef } from 'react';
import Lottie, { LottieRef } from 'lottie-react';

import PageNotFoundAnimation from '../../../assets/animations/page_not_found_animation.json';
import BackHomeButton from '../../../components/BackHomeButton';
import { Page } from '../../../layouts';

const NotFound = () => {
  const lottieRef = useRef<LottieRef>(null);

  return (
    <Page title='Page Not Found'>
      <div className='flex flex-col items-center px-8 py-8 text-white'>
        <div className='flex w-full justify-center'>
          <Lottie lottieRef={lottieRef as LottieRef} animationData={PageNotFoundAnimation} />
        </div>
        <div className='mb-6 flex w-full flex-col items-center gap-y-3'>
          <h3 className='text-center text-2xl font-bold text-[#4285F4]'>OPPS, PAGE NOT FOUND</h3>
          <p className='flex flex-wrap text-center text-[16px] font-normal leading-6'>
            We couldn't find any results for your search. Try again.
          </p>
        </div>
        <BackHomeButton />
      </div>
    </Page>
  );
};

export default NotFound;
