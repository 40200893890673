import placeholder from '../assets/images/plank.png';

import { Picture } from '../types';

export const PictureList: Picture[] = [
  {
    pictureId: '1',
    name: 'Picture 1',
    imageUrl: placeholder,
  },
  {
    pictureId: '2',
    name: 'Picture 2',
    imageUrl: placeholder,
  },
  {
    pictureId: '3',
    name: 'Picture 3',
    imageUrl: placeholder,
  },
  {
    pictureId: '4',
    name: 'Picture 4',
    imageUrl: placeholder,
  },
  {
    pictureId: '5',
    name: 'Picture 5',
    imageUrl: placeholder,
  },
];

export const FinalStory =
  'In the last part of his life, Endric not only created art but also spent a lot of time studying parallel worlds and spiritual things. He believed that the images in his paintings were not just real but were also doors to other dimensions. When he painted his last five pictures, Endric knew he had found a way to connect two worlds, and he left the paintings as a guide for someone who could continue his journey. Before he disappeared, he warned that one of the two worlds was becoming unstable and only someone who truly understood the deep meaning of the paintings could save both worlds. But did Endric really disappear, or is he still alive in another world?';
