import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as ExclamationMark } from '../../assets/svgs/ExclamationMark.svg';
import StoryModalIdle from '../../components/StoryModal';
import { Page } from '../../layouts';
import MinigameService from '../../services/minigame.service';
import { socket } from '../../socket';
import useBoundStore from '../../store';
import Loading from '../Loading';

import { DisplayQuestion, SocketEvent } from '../../types';

const MinigameQuestionList = () => {
  const userProfile = useBoundStore.use.user();
  const [questions, setQuestions] = useState<DisplayQuestion[]>([]);
  const isStoryOpen = useMemo(() => {
    let completedQuestions = questions?.filter((question) => question.isAnswered).length || 0;
    return completedQuestions >= 2;
  }, [questions]);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { pictureId } = useParams();

  const fetchMinigameQuestions = useCallback(async () => {
    if (!pictureId) return;
    setLoading(true);
    try {
      const response = await MinigameService.getAllQuestions(pictureId);
      let questionsList = Object.values(response.data).map((question, index) => ({
        questionId: index + 1,
        ...question,
      }));
      questionsList = questionsList.filter((question) => question.isUnlocked);
      if (questionsList.length === 0) {
        toast.error('You have not unlocked this picture');
        navigate('/pictures');
      } else setQuestions(questionsList);
    } catch (error) {
      console.error('Error fetching minigame questions:', error);
    } finally {
      setLoading(false);
    }
  }, [pictureId, navigate]);

  useEffect(() => {
    if (!pictureId) return;

    fetchMinigameQuestions();
  }, [pictureId, fetchMinigameQuestions]);

  useEffect(() => {
    socket.on(SocketEvent.QUESTION_CHANGE, fetchMinigameQuestions);

    return () => {
      socket.off(SocketEvent.QUESTION_CHANGE, fetchMinigameQuestions);
    };
  }, [fetchMinigameQuestions]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={`${userProfile.name}`}>
      <Link
        to='/pictures'
        className='ml-5 mt-4 block w-fit rounded-lg bg-[#db4437] px-4 py-1 text-white'
      >
        {'<'} Back
      </Link>
      <div className='mx-5 mt-6'>
        <div className='flex flex-col items-center'>
          <h1 className='text-lg font-bold'>Choose your questions</h1>
          <p className='text-center'>
            You must answer correctly at least two questions to unlock each picture. The more you
            answer, the more jigsaws you get
          </p>
        </div>
        <div className='mb-5 mt-10 flex flex-wrap justify-around'>
          {questions.map((question, index) => (
            <Link
              to={`/pictures/${pictureId}/questions/${question.questionId}`}
              key={question.questionId}
              className={`relative mt-3 flex aspect-square w-[45%] flex-col items-center justify-center rounded-lg ${
                question.isAnswered
                  ? 'pointer-events-none bg-[#fff6ed]'
                  : 'bg-[#fee3ba] focus:bg-[#FBB03B]'
              }`}
            >
              {question.isAnswered ? (
                <p className='text-black'>Completed</p>
              ) : (
                <h1 className='text-2xl font-bold text-black'>{index + 1}</h1>
              )}
              {question.showNotification && (
                <div className='absolute right-2 top-2 flex h-7 w-7 items-center justify-center rounded-full border border-[#db4437]'>
                  <ExclamationMark className='h-5 w-5 fill-[#db4437]' />
                </div>
              )}
            </Link>
          ))}
        </div>
        {isStoryOpen && (
          <div className='flex w-full justify-center'>
            <button
              onClick={() => setIsStoryModalOpen(true)}
              className='w-fit rounded-lg bg-[#FBB03B] px-5 py-2 text-center'
            >
              View Canvas's Story
            </button>
          </div>
        )}
      </div>
      <StoryModalIdle
        open={isStoryModalOpen}
        close={() => setIsStoryModalOpen(false)}
        header='Canvas Story'
        pictureId={pictureId?.toString() || ''}
        allowFetch
      />
    </Page>
  );
};

export default MinigameQuestionList;
